<template>
  <img alt="Bazar" src="@/assets/logotype.png" />
</template>

<script>
import Fingerprint2 from "@fingerprintjs/fingerprintjs";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  created() {
    if (window.requestIdleCallback) requestIdleCallback(this.getFingerPrint);
    else setTimeout(this.getFingerPrint, 500);
  },
  methods: {
    async getFingerPrint() {
      try {
        const { code } = this.$route.params;
        const fp = await new Promise((resolve) =>
          Fingerprint2.getV18((value, components) => {
            components.push({ key: "hwid", value });
            resolve(Buffer.from(JSON.stringify(components)).toString("base64"));
          })
        );

        const response = await fetch(
          `https://app.${this.domain}/api/affiliates/hit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({
              code,
              fp,
              referrer: document.referrer,
            }),
          }
        );

        const data = await response.json();
        if (data.success) location.href = `https://${this.domain}/r/${code}`;
        else location.href = `https://${this.domain}`;
      } catch (error) {
        location.href = `https://${this.domain}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  opacity: 0.2;
  animation: fade 2s linear infinite;
}

@keyframes fade {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
